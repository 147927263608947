import styled, { css } from 'styled-components';
import { theme } from 'ds4-beta';

export const WindowContainer = styled.div`
  border: 2px solid transparent;
  padding: 0px ${theme.space[3]} ${theme.space[3]} ${theme.space[3]};
  border-radius: ${theme.space[1]};
  background-image: linear-gradient(
      ${theme.color.grey[0]},
      ${theme.color.grey[0]}
    ),
    linear-gradient(
      to right,
      ${theme.color.lightPurpleShade[50]},
      ${theme.color.lightPurpleShade[900]}
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0px 4px 8px 0px rgba(18, 18, 19, 0.15);

  display: flex;
  flex-direction: column;
  height: calc(
    100vh - ${theme.space[9]}
  ); // calc to ensure header nav height is accounted for
  box-sizing: border-box;
  overflow: hidden;
`;

export const MessagesContainer = styled.div`
  flex: 1;
  padding: ${theme.space[3]};
  overflow-y: auto;
`;

export const UserInputContainer = styled.div`
  padding: ${theme.space[3]};
  border-top: 1px solid ${theme.color.grey[1000]};
  flex-shrink: 0; // prevent user input from shrinking
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${theme.space[4]};
`;

export const SectionHeader = styled.div`
  ${theme.typography.body1.medium};
  text-transform: capitalize;
  padding-bottom: ${theme.space[3]};
`;

export const HeaderContainer = styled.div<{
  isWindowScrolled?: boolean;
}>`
  display: flex;
  align-items: center;
  height: ${theme.space[4]};
  padding: ${theme.space[3]};
  margin: 0px -${theme.space[3]};
  ${({ isWindowScrolled }) =>
    isWindowScrolled
      ? css`
          ${theme.elevation[1]};
        `
      : `border-bottom: 1px solid ${theme.color.grey[200]};`}
`;

export const HeaderContentContainer = styled.div`
  height: ${theme.space[4]};
  display: flex;
  gap: ${theme.space[4]};
  width: 100%;
  justify-content: space-between;
`;
export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const IconContainer = styled.div`
  display: flex;
  width: ${theme.space[4]};
  margin-right: ${theme.space[1]};
`;
export const HeaderTitle = styled.div`
  ${theme.typography.body2.medium};
`;
export const ExpandIconContainer = styled.div``;
export const CloseIconContainer = styled.div`
  cursor: pointer;
`;
