import { FabAIController } from '@copilot/mfa-communication';
import { InputElementType } from '../types';

export const handleUserInput = (
  event: React.KeyboardEvent<InputElementType>,
  addMessage: (sender: 'user' | 'bot', content: string) => void,
  setCurrentPrompt: React.Dispatch<React.SetStateAction<string>>
) => {
  if (event.key === 'Enter' && event.currentTarget.value != "") {
    addMessage('user', event.currentTarget.value);
    setCurrentPrompt('');
  }
}

type ContentTypeCheckResult =
  | { type: 'string', value: string }
  | { type: 'CommerceGPTResponse', value: FabAIController.CommerceGPTResponse }
  | { type: 'Array<CommerceGPTDataObject>', value: Array<FabAIController.CommerceGPTDataObject> }
  | { type: 'Invalid' }

export const determineContentType = (input: any): ContentTypeCheckResult => {
  if (typeof input === 'string') {
    return { type: 'string', value: input }
  }
  if ((input as FabAIController.CommerceGPTResponse).hasOwnProperty('data')) {
    return { type: 'CommerceGPTResponse', value: input as FabAIController.CommerceGPTResponse }
  }
  if (Array.isArray(input)
    && input.every(item => (item as FabAIController.CommerceGPTDataObject).hasOwnProperty('title'))) {
    return { type: 'Array<CommerceGPTDataObject>', value: input as Array<FabAIController.CommerceGPTDataObject> }
  }
  return { type: 'Invalid' }
}

export const renderStatisticsResponseAsArray =
  (datum: FabAIController.CommerceGPTDataObject): Array<string> => {
    if (!datum?.title && !datum?.items) { return ['No values present']; }
    const result = [datum?.title];
    if (datum.items?.length) {
      datum.items.forEach(item => {
        if (item.statistics?.length) {
          item.statistics.forEach(stat => {
            result.push(`${stat.attribute}: ${stat.value}`.toUpperCase());
            const reasonsArray = stat.reasons as Array<string>;
            if (reasonsArray?.length) {
              result.push('Reasons');
              reasonsArray.forEach(reason => {
                result.push(`- ${reason}`);
              });
            }
            const suggestionsArray = stat.suggestions as Array<string>;
            if (suggestionsArray?.length) {
              result.push('Suggestions');
              suggestionsArray.forEach(suggestion => {
                result.push(`- ${suggestion}`);
              });
            }
            if (suggestionsArray?.length || reasonsArray?.length) {
              result.push('--------'); // Separator for easy reading
            }
          });
        }
      });
    }
    return result;
  }