import React from 'react';
import { theme } from 'ds4-beta';
import { FabAIController } from '@copilot/mfa-communication';
import { SECTION_TITLES } from '../constants';
import { SectionHeader, ActionButtonContainer } from '../window.style';
import { ChatMessageType } from '../../chat-bubble/types';
import ActionButton from '../../action-button';

const ActionButtonContent = ({
  message,
  content,
  addMessage
}: {
  message: ChatMessageType,
  content: Array<FabAIController.CommerceGPTDataObject>,
  addMessage: (sender: 'user' | 'bot', content: string) => void;
}) => {
  return (
    <>
      {message.sectionTitle && (
        <SectionHeader>{message.sectionTitle}</SectionHeader>
      )}
      <ActionButtonContainer>
        {content.map(datum => (
          <ActionButton
            key={`action-button-${datum.title}`}
            text={datum.title}
            borderColor={
              message.sectionTitle === SECTION_TITLES.SUGGESTED_QUESTIONS
                ? theme.color.lightPurpleShade[50]
                : undefined
            }
            onClick={() => {
              addMessage('user', datum.title);
            }}
          />
        ))}
      </ActionButtonContainer>
    </>
  );
};

export default ActionButtonContent;