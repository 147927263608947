import React from 'react';
import { FabAIController } from '@copilot/mfa-communication';
import { ChatMessageType } from '../../chat-bubble/types';
import { SectionHeader } from '../window.style';
import RecommendationCard from '../../recommendation-card';

const RecommendationContent = ({
  message,
  content,
}: {
  message: ChatMessageType,
  content: Array<FabAIController.CommerceGPTDataObject>,
}) => {
  return (
    <>
      {message.sectionTitle && <SectionHeader>{message.sectionTitle}</SectionHeader>}
      {content.map(datum => (
        <RecommendationCard
          key={`message-content-recommendation-${datum.title}`}
          recommendationData={datum} />
      ))}
    </>
  );
};

export default RecommendationContent;