import React from 'react';
import { GenericError } from 'ds4-beta';
import { ProductDescription } from './event-views';
import { StyledErrorContainer } from './style';
import { SUPPORTED_EVENTS, CHAT_ERROR_MESSAGE } from './constants';

export const JsonRenderer = ({ content, ...restProps }) => {
  const { event, data: rawData } = content;
  const data = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
  const { PRODUCT_DESCRIPTION, EXCEPTION } = SUPPORTED_EVENTS;

  switch (event) {
    case PRODUCT_DESCRIPTION:
      return <ProductDescription data={data} {...restProps} />;
    case EXCEPTION:
      return (
        <StyledErrorContainer {...restProps}>
          <GenericError error={CHAT_ERROR_MESSAGE} dataTestid='chat-error' />
        </StyledErrorContainer>
      );
    default:
      return null;
  }
};

export default JsonRenderer;
