import React from 'react';
import {
  CloseIconContainer,
  ExpandIconContainer,
  HeaderContainer,
  HeaderContentContainer,
  HeaderTitle,
  HeaderTitleContainer,
  IconContainer,
} from '../window.style';
import { Icon, theme } from 'ds4-beta';

const ChatHeader = ({ isWindowScrolled }: any) => {

  const hideChatWindow = () => {
    const applicationContent = document.getElementById('application-content');
    applicationContent.classList.toggle('show-ai-application');
  };
  
  return (
    <HeaderContainer
      data-Testid={'ai-chat-header'}
      isWindowScrolled={isWindowScrolled}
    >
      <HeaderContentContainer>
        <HeaderTitleContainer>
          <IconContainer>
            <Icon
              iconName='CommerceGPT'
              color={theme.color.lightPurpleShade[50]}
              size={16}
            />
          </IconContainer>
          <HeaderTitle data-Testid={'ai-chat-title'}>{'fabAI'}</HeaderTitle>
        </HeaderTitleContainer>

        <ExpandIconContainer></ExpandIconContainer>
        <CloseIconContainer
          data-Testid={'ai-chat-close-button'}
          onClick={hideChatWindow}
        >
          <Icon iconName='Close' color={theme.color.grey[1000]} size={16} />
        </CloseIconContainer>
      </HeaderContentContainer>
    </HeaderContainer>
  );
};
export default ChatHeader;
