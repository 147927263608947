import { Button, theme } from 'ds4-beta';
import React from 'react';
import { ActionButtonContainer } from './action-button.style';

const ActionButton = ({
  text,
  onClick,
  borderColor = theme.color.grey[400]
}: {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  borderColor?: string;
}) => {
  return (
    <ActionButtonContainer borderColor={borderColor}>
      {/* Will likely have to be changed from a button because
      the DS4 Button component was not designed to support long names */}
      <Button
        variant='primary'
        text={text}
        textLength={text.length}
        onClick={onClick} />
    </ActionButtonContainer>
  );
};

export default ActionButton;