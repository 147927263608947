import { theme } from 'ds4-beta';
import styled from 'styled-components';
export const customScrollbar = `
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.3s;

  &:hover {
    scrollbar-color: ${theme.color.grey[200]} transparent;
  }

  &:not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: ${theme.space[1]};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.grey[600]};
    border-radius: ${theme.space[1]};
    transition: background 0.3s;
  }
`;

export const StyledChatLayout = styled.div<{ hasMessages?: boolean }>`
  height: ${({ hasMessages }) =>
    hasMessages ? 'calc(100vh - 120px)' : '60px'};
  overflow-y: auto;
  ${({ hasMessages }) => hasMessages && `margin-top:${theme.space[4]}`};
  padding-right: ${theme.space[4]};
  background-color: ${theme.color.grey[0]};
  margin: 0 auto;
  @media (min-width: 1024px) {
    width: 808px;
  }

  @media (min-width: 375px) and (max-width: 1023px) {
    width: 80%;
  }

  .chat-response-wrapper {
    position: relative;
    left: -32px;
  }
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  ${customScrollbar};
`;

export const StyledChatContent = styled.div`
  padding-bottom: ${theme.space[6]};
  height: auto;
`;

export const StyledChatFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: ${theme.color.grey[0]};
  padding-bottom: ${theme.space[2]};
  @media (min-width: 1024px) {
    width: 808px;
  }

  @media (min-width: 375px) and (max-width: 1023px) {
    width: 75%;
  }
`;

export const StyledChatInput = styled.div`
  background-color: ${theme.color.grey[0]};
`;

export const StyledProductContainer = styled.div`
  max-width: 808px;
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[1000]};
`;

export const StyledIcon = styled.div`
  align-self: flex-start;
`;

export const StyledHeading = styled.span`
  ${theme.typography.body1.regular};
  display: inline-block;
  gap: ${theme.space[2]};
  align-items: center;
  margin-bottom: ${theme.space[4]};
`;

export const StyledBoldText = styled.span`
  ${theme.typography.body1.medium};
`;

export const StyledChatRef = styled.div`
  height: 200px;
`;

export const StyledChatStreamContainer = styled.div<{
  isLastAiMessage?: boolean;
}>`
  display: flex;
  gap: ${theme.space[2]};
  ${theme.typography.body1.medium};
  ${({ isLastAiMessage }) =>
    isLastAiMessage && `margin-bottom: ${theme.space[6]};`}
  margin-left: ${theme.space[4]};
`;

export const StyledErrorContainer = styled.div`
  margin-left: ${theme.space[4]};
`;
