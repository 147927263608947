import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const MainGreeting = styled.div`
  ${theme.typography.h4};
  span {
    ${theme.typography.h4};
  }
  padding: 1px ${theme.space[1]};
`;

export const GreetingDescription = styled.div`
  ${theme.typography.body2.regular};
  color: ${theme.color.grey[600]};
  padding: ${theme.space[2]} ${theme.space[1]};
`;

export const gradientTextCSS = {
  background: `linear-gradient(to right, ${theme.color.lightPurpleShade[50]}, ${theme.color.lightPurpleShade[900]})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};