import React from 'react';
import { MessageCardContainer, MessageCardHeaderContainer } from '../message-common/common.style';
import { RecommendationRowContainer } from './recommendation-card.style';
import { Icon, Tag } from 'ds4-beta';
import { FabAIController } from '@copilot/mfa-communication';

const RecommendationCard = ({ recommendationData }: { recommendationData: FabAIController.CommerceGPTDataObject }) => {
  return (
    <MessageCardContainer>
      <MessageCardHeaderContainer>
        <div className='message-card-header-title'>{recommendationData.title}</div>
        <div className='message-card-header-desc'>{recommendationData.description}</div>
      </MessageCardHeaderContainer>
      <div>
        {recommendationData.items.map(item => {
          const { content, metadata } = item;
          return (
            <RecommendationRowContainer key={`recommendation-row-container-${content}`}>
              <div className='recommendation-row-header'>
                {/* Icon is hardcoded for now, can be updated once backend sends back that info */}
                <Icon iconName='Analytics' />
                <span>{content}</span>
              </div>

              {Object.keys(metadata).map(datumKey => (
                <Tag
                  key={`recommendation-row-tag-${datumKey}`}
                  id={`recommendation-row-tag-${datumKey}`}
                  primaryLabel={`${datumKey}: ${metadata[datumKey]}`} />
              ))}
            </RecommendationRowContainer>
          );
        })}
      </div>
    </MessageCardContainer>
  );
};

export default RecommendationCard;