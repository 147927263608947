import React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import AIWindow from './window';

const unleashConfig = {
  url: process.env.UNLEASH_PROXY_URL ?? '',
  clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
  appName: 'copilot-fabai',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId') ?? '',
  },
};

const App = () => {
  return (
    <FlagProvider config={unleashConfig}>
      <AIWindow />
    </FlagProvider>
  );
};

export default App;
