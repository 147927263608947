import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const InsightCardStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.color.grey[200]};
  padding: ${theme.space[3]} 0;

  span {
    ${theme.typography.body1.medium};
  }
  
  .insight-stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .insight-stat {
      ${theme.typography.body1.regular};
      padding: 0 ${theme.space[2]} ${theme.space[2]} 0;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
`;