import React, { useEffect, useState } from 'react';
import { FabAILandingPageController } from '@copilot/mfa-communication';
import {
  InitialPromptsContainer,
  PredefinedChipContainer,
  StyledHeaderContainer,
  StyledIconContainer,
  StyledIconContainerRow,
  StyledIconLandingPage,
  StyledSubTitleContainer,
  StyledTitleContainer,
} from './landingPage.style';
import { AIChatPrompterButton, Icon } from 'ds4-beta';
import { ChipsIconsMapping } from './constants';

const LandingPagePrompts = ({ setSelectedChipQuery }) => {
  const [predefinedChips, setPredefinedChips] = useState([]);
  useEffect(() => {
    getPredefinedSuggestionChips();
  }, []);
  const getPredefinedSuggestionChips = async () => {
    const resp = await FabAILandingPageController.getPredefinedPrompts();
    setPredefinedChips(resp);
  };
  const handleChatPrompterClick = predefinedChip => {
    setSelectedChipQuery(predefinedChip?.title);
  };
  return (
    <StyledIconLandingPage>
      <StyledHeaderContainer>
        <StyledIconContainerRow>
          <StyledIconContainer>
            <Icon iconName='Fabric' size={24} />
          </StyledIconContainer>
        </StyledIconContainerRow>

        <StyledTitleContainer>
          Commerce Intelligence Delivered
        </StyledTitleContainer>
        <StyledSubTitleContainer>
          Get started with one of the prompts below or ask me something else
        </StyledSubTitleContainer>
      </StyledHeaderContainer>
      <InitialPromptsContainer padding={false}>
        {predefinedChips?.map((predefindeChip, index) => {
          return (
            <PredefinedChipContainer sm={12} md={6} lg={6}>
              <AIChatPrompterButton
                dataTestId={`predefined-chip-${index}`}
                iconName={ChipsIconsMapping[predefindeChip.type]}
                title={predefindeChip.title}
                onClick={() => handleChatPrompterClick(predefindeChip)}
              />
            </PredefinedChipContainer>
          );
        })}
      </InitialPromptsContainer>
    </StyledIconLandingPage>
  );
};
export default LandingPagePrompts;
