import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const MessageCardContainer = styled.div`
  position: relative;
  padding: ${theme.space[4]};
  margin-bottom: ${theme.space[4]};
  background: ${theme.color.grey[50]};

  ::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: ${theme.space[2]};
    border: 1px solid transparent;
    background: linear-gradient(
        to right,
        ${theme.color.lightPurpleShade[50]},
        ${theme.color.lightPurpleShade[900]}
      )
      border-box;
    -webkit-mask:
      linear-gradient(#888889 0 0) padding-box,
      linear-gradient(#888889 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const MessageCardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid ${theme.color.grey[200]};

  .message-card-header-title {
    ${theme.typography.body1.medium};
    padding-bottom: ${theme.space[1]};
  }

  .message-card-header-desc {
    ${theme.typography.body1.regular};
    color: ${theme.color.grey[600]};
    padding-bottom: ${theme.space[2]};
  }
`;