import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const ActionButtonContainer = styled.div<{ borderColor: string }>`
  max-width: 100%;
  padding-bottom: ${theme.space[2]};
  button {
    background: ${theme.color.grey[50]};
    color: ${theme.color.grey[1000]};
    ${theme.typography.body1.regular};
    padding: 0 ${theme.space[2]};
    border: 1px solid ${({ borderColor }) => borderColor};

    :hover {
      background: ${theme.color.grey[0]};
    }
  }
`;