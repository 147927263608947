import styled from 'styled-components';
import { theme } from 'ds4-beta';

export const RecommendationRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.color.grey[200]};
  padding: ${theme.space[3]} 0;

  .recommendation-row-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    span {
      padding-left: ${theme.space[2]};
      ${theme.typography.body1.medium};
    }
  }
`;