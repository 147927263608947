import { CHAT_MESSAGE_TYPE } from './constants';
const updateMessage = (message, messageId, events) => {
  if (message.id === messageId) {
    return {
      id: messageId,
      type: CHAT_MESSAGE_TYPE.AI,
      content: events,
    };
  }
  return message;
};

const createNewMessage = (messageId, events) => ({
  id: messageId,
  type: CHAT_MESSAGE_TYPE.AI,
  content: events,
});

export const updateMessages = (prevMessages, messageId, events) => {
  const updatedMessages = prevMessages.map(message =>
    updateMessage(message, messageId, events)
  );

  const messageExists = prevMessages.find(msg => msg.id === messageId);

  return messageExists
    ? updatedMessages
    : updatedMessages.concat(createNewMessage(messageId, events));
};

export const capitalize = (input, capitalizeAll) => {
  const [beforeDelimiter] = (input || '').split(/[_-]/);

  if (capitalizeAll) {
    return beforeDelimiter.toUpperCase();
  }

  return (
    beforeDelimiter.charAt(0).toUpperCase() +
    beforeDelimiter.slice(1).toLowerCase()
  );
};

export const determineBackendUrl = (backendSearchQuery: string): string => {
  switch (backendSearchQuery) {
    case 'dev-ecs':
      return 'https://dev.commerce-gpt.fabric.inc';
    case 'dev-eks':
      return 'https://dev.api.fabric.inc/v1';
    case 'stg-eks':
      return 'https://stg.api.fabric.inc/v1';
    default:
      return process.env.CHAT_STREAMING_URL;
  }
};

