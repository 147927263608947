import { useEffect, useRef, useState } from 'react';
import { ChatMessageType } from '../../chat-bubble/types';
import { FabAIController } from '@copilot/mfa-communication';
import { v4 as uuidv4 } from 'uuid';
import {
  SECTION_TITLES
} from '../constants';
import { MessageContentType } from '../../types';
import { AxiosResponse } from 'axios';

const useChat = () => {
  const userId = window.sessionStorage.getItem('userId');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [showChatBubbleLoading, setShowChatBubbleLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [isWindowScrolled, setIsWindowScrolled] = useState<boolean>(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const addMessage = async (
    sender: 'user' | 'bot',
    content: MessageContentType,
    sectionTitle?: string
  ) => {
    const newMessage: ChatMessageType = {
      id: uuidv4(),
      sender,
      content,
      sectionTitle: sectionTitle ?? '',
      timestamp: new Date().toLocaleTimeString(),
    };

    setMessages(prevState => ([
      ...prevState,
      newMessage
    ]));

    if (sender === 'user') {
      awaitBotResponse(content as string);
    }
  };

  const awaitBotResponse = async (content: string) => {
    const input: FabAIController.UserPrompt = {
      prompt: content,
      userId: userId,
      conversationGroup: FabAIController.CommerceGPTConversationGroupType.HOME, // hard-cording the conversation group for now, can be dynamic once fabAI is in multiple places
      ...(conversationId && {
        conversationId
      })
    }

    setTimeout(async () => {
      setShowChatBubbleLoading(true);
      scrollToBottom();

      const response: AxiosResponse<FabAIController.CommerceGPTResponse> = await FabAIController.sendUserPrompt(input);
      setShowChatBubbleLoading(false);
      setConversationId(response?.data?.conversationId);
      addMessage('bot', response?.data);
    }, 750); // to simulate the bot 'receiving' and then 'thinking' the message
  }

  useEffect(() => {
    const fetchInitialAPIData = async () => {
      const leadQuestionsMessage:
        Array<FabAIController.CommerceGPTDataObject> = await FabAIController.getLeadMessages(userId, FabAIController.CommerceGPTConversationGroupType.HOME); // hard-cording the conversation group for now, can be dynamic once fabAI is in multiple places
      const suggestedQuestionsMessage:
        Array<FabAIController.CommerceGPTDataObject> = await FabAIController.getSuggestedQuestions();
      const recommendationsMessage:
        Array<FabAIController.CommerceGPTDataObject> = await FabAIController.getSuggestedActions();
      const insightsMessage:
        Array<FabAIController.CommerceGPTDataObject> = await FabAIController.getInsights();

      // Should fade these in
      addMessage(
        'bot',
        leadQuestionsMessage.slice(0, Math.min(leadQuestionsMessage.length, 3)),
        SECTION_TITLES.LEAD_MESSAGES
      ); // truncating to 5 for now, can deal with pagination later
      addMessage('bot', suggestedQuestionsMessage, SECTION_TITLES.SUGGESTED_QUESTIONS);
      addMessage('bot', recommendationsMessage, SECTION_TITLES.SUGGESTED_ACTIONS);
      addMessage('bot', insightsMessage, SECTION_TITLES.SUGGESTED_INSIGHTS);

      scrollToBottom();
    }

    fetchInitialAPIData();

  }, []);

  useEffect(() => {
    scrollToBottom();
    setIsWindowScrolled(true);
  }, [messages]);

  return {
    messages,
    currentPrompt,
    setCurrentPrompt,
    showChatBubbleLoading,
    messagesEndRef,
    addMessage,
    isWindowScrolled
  };
}

export default useChat;