import React from 'react';
import { getPrimaryGreeting, GREETING_DESCRIPTION, SECONDARY_GREETING } from './constants';
import { GreetingDescription, MainGreeting } from './styles';

const Greeting = () => {
  const userFirstName = sessionStorage.getItem('firstName');
  return (
    <div>
      <MainGreeting>{getPrimaryGreeting(userFirstName)}</MainGreeting>
      <MainGreeting>{SECONDARY_GREETING}</MainGreeting>
      <GreetingDescription>{GREETING_DESCRIPTION}</GreetingDescription>
    </div>
  );
};

export default Greeting;