import React from 'react';
import ChatBubble from '../chat-bubble';
import {
  MessagesContainer,
  UserInputContainer,
  WindowContainer
} from './window.style';
import Greeting from '../greeting';
import { ChatMessageType } from '../chat-bubble/types';
import { FabAIController } from '@copilot/mfa-communication';
import { Input } from 'ds4-beta';
import { handleUserInput, determineContentType } from './window.utils';
import { InputElementType } from '../types';
import { MESSAGE_INPUT_FIELD_TITLE } from './constants';
import ChatBubbleLoadingIcon from '../chat-bubble/loading';
import useChat from './hooks/useChat';
import ActionButtonContent from './components/action-button-content';
import RecommendationContent from './components/recommendation-content';
import InsightContent from './components/insight-content';
import ChatHeader from './components/header';

const AIWindow = () => {
  const {
    messages,
    currentPrompt,
    setCurrentPrompt,
    showChatBubbleLoading,
    messagesEndRef,
    addMessage,
    isWindowScrolled
  } = useChat();

  const renderMessageContent = (message: ChatMessageType) => {
    const contentType = determineContentType(message.content);
    if (contentType.type === 'string' || contentType.type === 'CommerceGPTResponse') {
      return (
        <ChatBubble
          key={`chat-bubble-${message.id}`}
          message={message}
          sender={message.sender}
        />
      );
    }

    if (contentType.type === 'Array<CommerceGPTDataObject>') {
      if (!contentType.value.length) { return null; }
      const firstValueType = contentType.value[0].type;

      switch (firstValueType) {
        case FabAIController.CommerceGPTMessageType.ACTION:
          return (
            <ActionButtonContent
              message={message}
              content={contentType.value}
              addMessage={addMessage} />
          );
        case FabAIController.CommerceGPTMessageType.RECOMMENDATION:
          return (
            <RecommendationContent
              message={message}
              content={contentType.value} />
          )
        case FabAIController.CommerceGPTMessageType.INSIGHT:
          return (
            <InsightContent
              message={message}
              content={contentType.value} />
          )
        default:
          return null;
      }
    }
    return null;
  }

  return (
    <WindowContainer>
      <ChatHeader isWindowScrolled={isWindowScrolled} />
      <MessagesContainer>
        <Greeting />
        {messages.map(message => renderMessageContent(message))}
        {showChatBubbleLoading && <ChatBubbleLoadingIcon />}
        <div ref={messagesEndRef}></div>
      </MessagesContainer>
      <UserInputContainer>
        <Input
          label={MESSAGE_INPUT_FIELD_TITLE}
          width='100%'
          inputProps={{
            onKeyPress:
              (event: React.KeyboardEvent<InputElementType>) => {
                handleUserInput(event, addMessage, setCurrentPrompt)
              },
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentPrompt(event.target.value)
            },
            value: currentPrompt,
            dataTestid: 'message-fabai-input'
          }} />
      </UserInputContainer>
    </WindowContainer>
  );
};

export default AIWindow;
