import React from 'react';
import { FabAIController } from '@copilot/mfa-communication';
import { ChatMessageType } from '../../chat-bubble/types';
import { SectionHeader } from '../window.style';
import InsightCard from '../../insight-card';

const InsightContent = ({
  message,
  content,
}: {
  message: ChatMessageType,
  content: Array<FabAIController.CommerceGPTDataObject>,
}) => {
  return (
    <>
      {message.sectionTitle && <SectionHeader>{message.sectionTitle}</SectionHeader>}
      {content.map(datum => (
        <InsightCard
          key={`message-content-insight-${datum.title}`}
          insightData={datum} />
      ))}
    </>
  );
};

export default InsightContent;