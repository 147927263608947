import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import { theme } from 'ds4-beta';
import JsonRenderer from './json-renderer';
import {
  CHAT_STREAMING_PATH,
  CHAT_STREAMING_PAYLOAD,
  CHAT_MESSAGE_TYPE,
  SUPPORTED_EVENT_TYPES,
} from './constants';
import { determineBackendUrl, updateMessages } from './util';

export function TextWithLineBreaks({ text }) {
  let count = 0;
  const textWithBreaks = text
    .replace(/\[NEWLINE\]+/g, '\n')
    .split('\n')
    .map(text => (
      <div style={theme.typography.body1.regular} key={`${text}-${count++}`}>
        {text}
        <br />
      </div>
    ));

  return <>{textWithBreaks}</>;
}

export const useChatStreaming = ({ content, setLoading }) => {
  const [messages, setMessages] = useState([]);

  const renderMessages = useMemo(() => {
    const renderedMessages = [];
    let currentText = '';

    messages.forEach((msg, index) => {
      if (msg.eventType === SUPPORTED_EVENT_TYPES.TEXT) {
        currentText += msg.data;
      }

      if (
        msg.eventType === SUPPORTED_EVENT_TYPES.JSON ||
        index === messages.length - 1
      ) {
        if (currentText) {
          renderedMessages.push(
            <span
              key={`${msg.id}-text`}
              data-testid={`text-response-${index}`}
              className='fade-in'
            >
              <TextWithLineBreaks text={currentText} />
            </span>
          );
          currentText = '';
        }

        if (msg.eventType === SUPPORTED_EVENT_TYPES.JSON) {
          renderedMessages.push(
            <JsonRenderer
              key={msg.id}
              content={msg}
              className='fade-in'
              data-testid={`json-response-${index}`}
            />
          );
        }
      }
    });
    return renderedMessages;
  }, [messages]);

  useEffect(() => {
    setMessages(content);
  }, [content]);

  useEffect(() => {
    if (messages.length > 0) {
      setLoading(false);
    }
  }, [messages]);

  return { messages, renderMessages };
};

export const useChatContainer = ({ setShowDefaultView, selectedChipQuery }) => {
  let [searchParams] = useSearchParams();
  const backendUrl = useRef(process.env.CHAT_STREAMING_URL);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const chatContainerRef = useRef(null);
  const label = useMemo(
    () =>
      ['firstName', 'lastName']
        .map(key => sessionStorage.getItem(key) || '')
        .join(' '),
    []
  );

  useEffect(() => {
    if (searchParams.get('backend')) {
      backendUrl.current = determineBackendUrl(searchParams.get('backend'))
    }
  }, [searchParams]);

  useEffect(() => {
    if (messages.length !== 0) {
      setShowDefaultView(false);
    }
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    chatContainer.scrollIntoView({
      top: chatContainer.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  useEffect(() => {
    if (selectedChipQuery?.length > 0) {
      setInput(selectedChipQuery);
      handleInputSubmit(selectedChipQuery);
      setInput('');
    }
  }, [selectedChipQuery]);

  const fetchStream = async (promptText: string) => {
    setLoading(true);
    setInProgress(true);
    try {
      const messageId = uniqueId();
      const response = await fetch(
        `${backendUrl.current}${CHAT_STREAMING_PATH}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
          },
          body: JSON.stringify({
            prompt: promptText,
            ...CHAT_STREAMING_PAYLOAD,
          }),
        }
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      const processStream = async () => {
        try {
          let events = [];
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              handleStreamCompletion();
              break; // Stream is finished
            }

            buffer += decoder.decode(value, { stream: true });
            buffer = processBuffer(buffer, events);

            updateMessagesState(events, messageId);
          }
        } catch (err) {
          console.error(err);
        }
      };

      processStream();
    } catch (err) {
      setLoading(false);
      setInProgress(false);
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.ERROR, content: err.message },
      ]);
    }
  };

  const processBuffer = (buffer, events) => {
    let boundary = buffer.indexOf('\n\n');
    while (boundary !== -1) {
      const eventChunk = buffer.slice(0, boundary); // Get event chunk
      buffer = buffer.slice(boundary + 2); // Remove processed chunk from buffer
      processEventChunk(eventChunk, events);
      boundary = buffer.indexOf('\n\n');
    }
    return buffer;
  };

  const processEventChunk = (eventChunk, events) => {
    const lines = eventChunk.split('\n').map(line => line.trim());
    const dataLine = lines.find(line => line.startsWith('data:'));

    if (dataLine) {
      const eventData = dataLine.replace('data:', '').trim();
      const parsedData = safelyParseJSON(eventData);

      if (parsedData) {
        addParsedDataToEvents(parsedData, events);
      }
    }
  };

  const safelyParseJSON = eventData => {
    try {
      return JSON.parse(eventData); // Parse the data as JSON
    } catch (err) {
      console.error('Error parsing data:', err);
      return null;
    }
  };

  const addParsedDataToEvents = (parsedData, events) => {
    const { id, event, eventType, data } = parsedData;
    // Add trimmed event
    events.push({
      id: id.trim(),
      eventType: eventType.trim(),
      data,
      event: event.trim(),
    });
  };

  const updateMessagesState = (events, messageId) => {
    // Sort and set messages in state
    events = events.map((ev, index) => ({
      ...ev,
      showFabAiIcon: index === 0,
    }));

    events.sort((a, b) => a.id - b.id);
    setMessages(prev => updateMessages(prev, messageId, events));
  };

  const handleStreamCompletion = () => {
    setLoading(false);
    setInProgress(false);
  };

  const handleSubmit = async () => {
    handleInputSubmit(input);
    setInput('');
  };

  const handleInputSubmit = async (inputValue: string) => {
    if (inputValue.length > 0 && !inProgress && !loading) {
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.USER, content: inputValue },
      ]);
      await fetchStream(inputValue);
    }
  };

  return {
    messages,
    label,
    loading,
    setLoading,
    chatContainerRef,
    input,
    handleSubmit,
    inProgress,
    setInput,
    backendUrl,
  };
};
