import React, { ReactNode, useMemo } from 'react';
import { StyledChatBubbleContainer } from './styles';
import { ChatBubbleType } from './types';
import { ChatBubble, ChatSenderType } from 'ds4-beta';
import { MessageContentType } from '../types';
import { FabAIController } from '@copilot/mfa-communication';
import { renderStatisticsResponseAsArray } from '../window/window.utils';

const ChatBubbleContainer = ({
  message,
  sender,
  dataTestid,
}: ChatBubbleType) => {
  const userName = useMemo(() => {
    const firstName = window.sessionStorage.getItem('firstName');
    const lastName = window.sessionStorage.getItem('lastName');
    return firstName + ' ' + lastName;
  }, []);

  const renderMessageContent = (content: MessageContentType) => {
    if (typeof content === 'string') {
      return content as ReactNode;
    }

    // Otherwise it is a CommerceGPTResponse
    return (content as FabAIController.CommerceGPTResponse).data
      .map(datum => {
        return renderStatisticsResponseAsArray(datum);
      })
      .join('');
  };

  const content = renderMessageContent(message.content);

  return (
    <StyledChatBubbleContainer sender={message.sender}>
      <ChatBubble
        {...(message.sender === ChatSenderType.USER && {
          avatar: { label: userName },
        })}
        sender={sender}
        content={content}
        {...(dataTestid && { dataTestid })}
      />
    </StyledChatBubbleContainer>
  );
};

export default ChatBubbleContainer;
