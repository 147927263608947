import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { capitalize } from '../util';
import { Box, theme, AIChatResponse } from 'ds4-beta';
import {
  StyledProductContainer,
  StyledBoldText,
  StyledHeading,
} from '../style';

export const ProductDescription = ({ data, ...restProps }) => {
  return (
    <StyledProductContainer {...restProps}>
      {data?.response?.map(res => {
        return (
          <>
            {data?.response[0]?.element && data?.response[0]?.analysis && (
              <StyledHeading>
                I’ve analyzed the description of Product{' '}
                <StyledBoldText>{res?.element?.identifier}. </StyledBoldText>
              </StyledHeading>
            )}

            {res?.element?.attributes[0]?.name && (
              <div className='chat-response-wrapper'>
                <AIChatResponse
                  title={res?.element?.attributes[0]?.name}
                  pill={res?.element?.attributes[0]?.score}
                  pillType={(
                    res?.element?.attributes[0]?.score || ''
                  ).toUpperCase()}
                  description={[res?.element?.attributes[0].value]}
                />
              </div>
            )}
            {!isEmpty(res.analysis) && (
              <>
                <div
                  className='chat-response-wrapper'
                  style={{ marginBottom: theme.space[4] }}
                >
                  <AIChatResponse title='Description Analysis' />
                </div>
                {res.analysis.parameters.map(param => {
                  const _title = capitalize(
                    param.attribute,
                    param.attribute === 'seo_optimized'
                  );
                  return (
                    <Box key={param.attribute}>
                      <div className='chat-response-wrapper'>
                        <AIChatResponse
                          title={_title}
                          pill={param.value}
                          pillType={param.value.toUpperCase()}
                          description={param.suggestions}
                        />
                      </div>
                    </Box>
                  );
                })}
              </>
            )}
            {!isEmpty(res?.recommendations[0]?.analysis) && (
              <>
                <div
                  className='chat-response-wrapper'
                  style={{ marginBottom: theme.space[4] }}
                >
                  <AIChatResponse title='Recommendations' />
                </div>
                {res?.recommendations[0]?.value && (
                  <div className='chat-response-wrapper'>
                    <AIChatResponse
                      title='Optimised description'
                      description={[res?.recommendations[0]?.value]}
                    />
                  </div>
                )}
                {res.recommendations[0].analysis.parameters.map(rec => {
                  const _title = capitalize(
                    rec.attribute,
                    rec.attribute === 'seo_optimized'
                  );
                  return (
                    <div key={rec.attribute} className='chat-response-wrapper'>
                      <AIChatResponse
                        title={_title}
                        description={rec.reasons}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
      })}
    </StyledProductContainer>
  );
};
