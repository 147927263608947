import React from 'react';
import { ChatBubbleLoadingContainer, ChatLoaderText, ChatLoadingContainer } from './styles';

const ChatBubbleLoadingIcon: React.FC = () => {
  return (
    <ChatLoadingContainer>
      <ChatBubbleLoadingContainer>
        <svg
          width='26'
          height='26'
          viewBox='0 0 26 26'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1'
            stroke='url(#paint0_linear_8_1017)'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_8_1017'
              x1='5'
              y1='22'
              x2='23.5'
              y2='5'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#9C21FC' />
              <stop offset='0.3' stopColor='#440FB0' />
            </linearGradient>
          </defs>
        </svg>
      </ChatBubbleLoadingContainer>
      <ChatLoaderText>{'Response incoming...'}</ChatLoaderText>
    </ChatLoadingContainer>
  );
};

export default ChatBubbleLoadingIcon;
