import React from 'react';
import {
  InsightCardStatsContainer,
} from './insight-card.style';
import { Tag } from 'ds4-beta';
import { MessageCardContainer, MessageCardHeaderContainer } from '../message-common/common.style';
import { FabAIController } from '@copilot/mfa-communication';

const InsightCard = ({ insightData }: { insightData: FabAIController.CommerceGPTDataObject }) => {
  return (
    <MessageCardContainer>
      <MessageCardHeaderContainer>
        <div className='message-card-header-title'>{insightData.title}</div>
        <div className='message-card-header-desc'>{insightData.description}</div>
      </MessageCardHeaderContainer>
      <div>
        {insightData.items.map(item => {
          const { content, statistics } = item;
          return (
            <InsightCardStatsContainer key={`insight-row-container-${content}`}>
              <span>{content}</span>
              <div className='insight-stats-container'>
                {statistics.map(datum => {
                  return (
                    <div key={`insight-row-tag-${datum.attribute}`} className='insight-stat'>
                      <Tag
                        id={`insight-row-tag-${datum.attribute}`}
                        primaryLabel={`${datum.attribute}: ${datum.value}`} />
                    </div>
                  )
                })}
              </div>
            </InsightCardStatsContainer>
          );
        })}
      </div>
    </MessageCardContainer >
  );
};

export default InsightCard;